<template>
<div>
    <ModalNewHelper></ModalNewHelper>
    <div  v-if="customHelper" :class="{ spaceHelper: !isMobile, spaceHelperMobile: isMobile }" >
      <span >
              <iframe
                v-if="linkIframe !== null && linkIframe !== ''"
                :src="linkIframe"
                width="100%"
                frameborder="0"
              ></iframe>
            </span>
    </div>
    <b-container v-else>
      <b-row class="pt-3" >
          <b-col  cols="12" :md="permission_filters ? '4' : '9'" class="spaceTitle">
              <div class="arrowBack text-center d-block d-md-none" @click="$router.go(-1)">
                  <font-awesome-icon icon="fa-solid fa-chevron-left" />
              </div>
              <p>{{ $t('helper.help_center') }}</p>
          </b-col>
          <b-col v-if="permission_filters" cols="5" md="3" class="colSearch">
              <div class="inputContainer">
                  <b-form-input :placeholder="$t('helper.search_ticket')" class="inputSearchV2" @change="getAllChamado('search')" v-model="searchTicket"></b-form-input>
                  <img src="@/assets/icons/search.svg" @click="getAllChamado('search')" class="Pointer search" />
              </div>
          </b-col>
          <b-col v-if="permission_filters" cols="7" md="2" class="colStatus">
              <div class="dropdown selectStatus" v-on:click="show = !show">
                  <button class="selectStatusButton">
                      <font-awesome-icon v-if="selectedItem" class="mr-2" :icon="selectedItem.icon" :style="{ color: 'var(--maincolor)' }" />
                      {{ selectedList }}
                  </button>
                  <div class="dropdown-content" v-show="show">
                      <a href="#" v-for="(item, index) in items" :key="index" @click.stop="selectItem(item)">
                          <font-awesome-icon :icon="item.icon" class="mr-2" :style="{ color: isSelected(item) ? 'var(--maincolor)' : ''}" />
                          {{ item.text }}
                      </a>
                  </div>
              </div>
          </b-col>
          <b-col cols="12" md="3" class="colButton"><button class="btnNewChamado w-100" @click="openModal">
                  {{ $t('helper.open_ticket') }}
              </button>
          </b-col>
      </b-row>
      <b-col class="layoutTicket p-0">
          <transition name="fade">
              <div class="loading" v-show="loadingList">
                  <span class="fa fa-spinner fa-spin"></span> {{ $t('helper.loading') }}
              </div>
          </transition>
          <b-col cols="12" md="4" class="summaryList">
              <div v-infinite-scroll="loadMore" infinite-scroll-disabled="isLoading" infinite-scroll-distance="10">
                  <div class="summaryBox" v-for="(item, index) in AllChamados" :key="item.id" :class="{activeRow: item.active}" :data-id="item.id" v-on:click="view(item)" @click="toggleActive(index)" :style="{ background: item.clicked ? 'var(--background2-v2)' : '' }">
                      <div class="divPicProfile">
                          <img v-if="item.member.picture" class="picProfile" :src="item.member.picture" alt="perfil" />
                          <div v-else class="divNoPicProfile" :style="{ backgroundColor: randomBgColor }">
                              <p :style="{ color: randomColor }">{{ firstLetter(item.member.name) }}</p>
                          </div>
                      </div>
                      <div class="divDetails">
                          <div>
                              <div class="d-flex details">
                                  <div class="datailsName">{{ item.member.name }}</div>
                                  <div class="detailsDate">{{ item.updated_at | moment("DD/MM/YYYY HH:mm") }}</div>
                              </div>
                              <div v-if="(item.last_message) && item.last_message.message " class="detailsLastMsg">{{ item.last_message.message }}</div>
                              <div v-if="(item.last_message) && !item.last_message.message " class="detailsLastMsg">Imagem</div>
                              <div v-if="(!item.last_message)" class="detailsLastMsg noMsg">*Ainda não existe mensagem aqui.</div>
                          </div>
                      </div>
                  </div>
              </div>
          </b-col>
          <b-col cols="12" md="8" class="divTicket" v-if="rowSelected.subject">
              <div class="titleTicket mt-4">
                  <div class="arrowBack text-center d-block d-md-none" @click="$router.go(-1)">
                      <font-awesome-icon icon="fa-solid fa-chevron-left" />
                  </div>
                  <p>{{ rowSelected.subject }}</p>
                  <div class="d-none d-md-block">
                      <button v-if="rowSelected.status !== 'concluded'" v-on:click="concluir(rowSelected.id)"> {{ $t('helper.close_ticket') }}</button>
                  </div>
              </div>
              <b-row class="divDatailTicket">
                  <b-col md="12" lg="4" xl="4" class="mb-2">
                      <p class="mb-0" v-if="rowSelected.status === 'open'">
                          <font-awesome-icon class="iconDatailTicket" icon="fa-regular fa-square-minus" />{{ $t('helper.opened_ticket') }}
                      </p>
                      <p class="mb-0" v-else-if="rowSelected.status === 'concluded'">
                          <font-awesome-icon icon="fa-solid fa-ban" class="iconDatailTicket" />{{ $t('helper.closed_ticket') }}
                      </p>
                  </b-col>
                  <b-col md="12" lg="5" class="mb-2">
                      <p class="dateDetailTicket mb-0">
                          <font-awesome-icon icon="fa-regular fa-clock" class="iconDatailTicket" />{{ $t('helper.updated_ticket') }} {{ rowSelected.updated_at | moment("DD/MM/YYYY HH:mm") }}</p>
                  </b-col>
                  <b-col cols="12" md="12">
                      <div class="d-block d-md-none mb-4">
                          <button class="closeTicket" v-if="rowSelected.status !== 'concluded'" v-on:click="concluir(rowSelected.id)"> {{ $t('helper.close_ticket') }}</button>
                      </div>
                  </b-col>
              </b-row>
              <div class="spaceModal">
                  <div class="spaceInputs">
                      <b-form-group label-for="capa">
                          <div id="spaceMsgs" class="spaceMsgs">
                              <div v-for="(mensagem) in getUnicChamado" :key="mensagem.id">
                                  <div v-if="mensagem.message !== null">
                                      <div>
                                          <div class="textInfoMember">
                                              <img v-if="mensagem.member.picture" class="picProfile" :src="mensagem.member.picture" alt="perfil" />
                                              <div v-else class="divNoPicProfile" :style="{ backgroundColor: randomBgColor }">
                                                  <p :style="{ color: randomColor }">{{ firstLetter(mensagem.member.name) }}</p>
                                              </div>
                                              <div class="text1">
                                                  <span style="font-size: 16px;">
                                                    {{ mensagem.member.name }}
                                                  </span>
                                                  <br>
                                                  <p style="font-size: 14px;">{{ mensagem.member.email }}</p>
                                              </div>
                                              <div class="text2">
                                                  {{ mensagem.created_at | moment("DD/MM/YYYY HH:mm:ss") }}
                                              </div>
                                          </div>
                                      </div>
                                      <p class="txtMsg animationOpacity">
                                          {{ mensagem.message }}
                                      </p>
                                  </div>
                                  <div v-else>
                                      <div>
                                          <div class="textInfoMember">
                                              <img v-if="mensagem.member.picture" class="picProfile" :src="mensagem.member.picture" alt="perfil" />
                                              <div v-else class="divNoPicProfile" :style="{ backgroundColor: randomBgColor }">
                                                  <p :style="{ color: randomColor }">{{ firstLetter(mensagem.member.name) }}</p>
                                              </div>
                                              <div class="text1">
                                                  {{ mensagem.member.name }}
                                                  <br>
                                                  <p>{{ mensagem.member.email }}</p>
                                              </div>
                                              <div class="text2">
                                                  {{ mensagem.created_at | moment("DD/MM/YYYY HH:mm:ss") }}
                                              </div>
                                          </div>
                                      </div>
                                      <div :class="{ imgDivMsgMy: mensagem.is_mine, imgDivMsg: !mensagem.is_mine }">
                                          <Lightbox :thumbnail="mensagem.media_url" :images="mensagem.media_url"></Lightbox>
                                      </div>
                                  </div>
                              </div>
                          </div>
                      </b-form-group>
                      <div class="spaceInputs" v-if="rowSelected.status !== 'concluded'">
                          <div class="divSpaceInputs">
                              <div class="d-flex">
                                  <div class="divPicSpaceInputs">
                                      <img v-if="rowSelected.member.picture" class="picProfile" :src="rowSelected.member.picture" alt="perfil" />
                                      <div v-else class="divNoPicProfile" :style="{ backgroundColor: randomBgColor }">
                                          <p :style="{ color: randomColor }">{{ firstLetter(rowSelected.member.name) }}</p>
                                      </div>
                                  </div>
                                  <div class="divReply">
                                      <p class="divReply1">{{ $t('helper.reply') }}</p>
                                      <p class="divReply2">{{ $t('helper.to') }}</p>
                                      <p class="divReply3">{{ rowSelected.member.name }}</p>
                                  </div>
                              </div>
                              <div class="d-none d-md-block">
                                  <button class="btnNewArchive" v-if="!midiaUpload" @click="midiaUpload = true">{{ $t('helper.add_archive') }}</button>
                              </div>
                          </div>
                          <div style="position: relative;">
                              <b-form-textarea id="textarea" v-model="descricaoChamado" :placeholder="$t('helper.modal.input3')" class="textAreaReply" :class="{ 'border-red': descricaoChamadoExcedido }" rows="5" max-rows="6" v-on:keyup.enter="createMsg" v-if="!midiaUpload">
                              </b-form-textarea>
                              <p class="countLength" :style="{ color: descricaoChamado.length > 255 ? 'red' : '' }">
                                  {{ descricaoChamado.length }} / 255
                              </p>
                          </div>
                          <vue-upload-multiple-image v-if="midiaUpload" class="animationOpacity toCenterUpload" @upload-success="uploadImageSuccess" @before-remove="beforeRemove" @edit-image="editImage" @data-change="dataChange" :data-images="images" :multiple="false" :primaryText="''" :popupText="$t('helper.modal.input4')" :dragText="$t('helper.modal.input5')" :browseText="$t('helper.modal.input6')"></vue-upload-multiple-image>
                      </div>
                      <b-row class="mt-3">
                          <b-col cols="6" md="6">
                              <div class="d-block d-md-none">
                                  <button class="btnNewArchive" v-if="!midiaUpload" @click="midiaUpload = true">{{ $t('helper.add_archive') }}</button>
                              </div>
                          </b-col>
                          <b-col cols="6" md="6">
                              <div class="spaceFooter animationOpacity mb-3" v-if="rowSelected.status !== 'concluded'">
                                  <button class="btnBack" v-if="midiaUpload" @click="midiaUpload = false">
                                      {{ $t('helper.back') }}
                                  </button>
                                  <button class="btnNewChamado" v-if="!midiaUpload" @click="createMsg">
                                      {{$t('helper.modal.input7')}}
                                  </button>
                                  <button class="btnNewChamado" v-if="midiaUpload" @click="createMsgImg">
                                      {{$t('helper.modal.input8')}}
                                  </button>
                              </div>
                          </b-col>
                      </b-row>
                  </div>
              </div>
          </b-col>
      </b-col>
    </b-container>
  </div>
</template>

<script>
import TicketService from "@/services/resources/TicketService";
const serviceTicket = TicketService.build();
import Cookies from "js-cookie";
import VueUploadMultipleImage from "vue-upload-multiple-image";
import Lightbox from "@/components/Lightbox.vue";
import InfiniteLoading from "vue-infinite-loading";
import MetaService from "@/services/resources/MetaService";
const serviceMeta = MetaService.build();
import MemberService from "@/services/resources/MemberService";
const serviceMember = MemberService.build();
import ModalNewHelper from "@/components/V2/modals/ModalNewHelper";
import TicketComponentV2 from "@/components/V2/TicketComponent";
import notify from "@/services/libs/notificacao";

export default {
    components: {
        VueUploadMultipleImage,
        Lightbox,
        InfiniteLoading,
        ModalNewHelper,
        TicketComponentV2
    },
    watch: {
        searchTicket: function () {
            this.getAllChamado('search');
        }
    },
    data() {
        return {
            users: [],
            client: {
                width: 0,
            },
            images: [],
            AllChamados: [],
            titleChamado: "",
            descricaoChamado: "",
            getUnicChamado: [],
            lastPage: 1,
            currentPage: 1,
            permission: false,
            midiaUpload: false,
            searchTicket: "",
            firstName: "",
            phone: "",
            rowSelected: [],
            pageTicket: 1,
            customHelper: false,
            linkIframe: null,
            siteId: 0,
            loading: false,
            item: {
                member: {
                    name: '',
                    picture: ''
                }
            },
            randomColor: '',
            randomBgColor: '',
            show: false,
            selectedList: 'Status',
            selectedListValue: '',
            selectedItem: '',
            items: [{
                    text: this.$t("helper.status_filter.all_calls"),
                    icon: 'fa-solid fa-layer-group',
                    value: '',
                },
                {
                    text: this.$t("helper.status_filter.opened"),
                    icon: 'fa-regular fa-square-minus',
                    value: 'open',
                },
                {
                    text: this.$t("helper.status_filter.closed_calls"),
                    icon: 'fa-solid fa-ban',
                    value: 'concluded',
                }
            ],
            isClicked: false,
            loadingList: null,
            permission_filters: false,
        };
    },
    created() {
        window.addEventListener("resize", this.handleResize);
        this.handleResize();
        this.randomColor = this.getRandomRgbColor();
        this.randomBgColor = this.addOpacity(this.randomColor, 0.4);
    },
  computed: {
        currentSite() { 
          return this.$store.getters.currentSite;
        },
        theme() {
            return this.$store.state.globalSettings.theme;
        },
        clubVersion() {
            return this.$store.state.globalSettings.clubVersion;
        },
        isMobile() {
            return this.client.width <= 576;
        },
        descricaoChamadoExcedido() {
            return this.descricaoChamado.length > 255;
        }
    },
    methods: {
        firstLetter(name) {
            return name.charAt(0).toUpperCase();
        },
        getRandomRgbColor() {
            let color = 'rgb(';
            for (let i = 0; i < 3; i++) {
                color += Math.floor(Math.random() * 256) + ',';
            }
            color = color.slice(0, -1);
            color += ')';
            return color;
        },
        addOpacity(rgbColor, opacity) {
            return rgbColor.replace('rgb', 'rgba').replace(')', `,${opacity})`);
        },
        setColor() {
            if (getComputedStyle(document.documentElement).getPropertyValue('--maincolor')) {
                return getComputedStyle(document.documentElement).getPropertyValue('--maincolor')
            }
        },
        toggleActive(index) {
            this.AllChamados.forEach((item, itemIndex) => {
                if (itemIndex !== index) {
                    item.clicked = false;
                }
            });
            let item = this.AllChamados[index];
            if (this.isMobile) {
                this.$router.push(`/ajuda/${item.id}`);
            }
            item.active = !item.active;
            item.clicked = !item.clicked;
            this.$set(this.AllChamados, index, item);
        },
        selectItem(item) {
            this.show = false;
            this.selectedList = item.text;
            this.selectedItem = item;
            this.selectedListValue = item.value;
            this.getList(this.selectedListValue);
            this.getAllChamado('search');
        },
        isSelected(item) {
            return this.selectedItem === item;
        },
        getList(item) {
            this.selectedListValue = item;
            this.currentPage = 1;
            this.getAllChamado();
        },
        uploadImageSuccess(formData, index, fileList) {
            this.imageSelected = fileList[0];
        },
        beforeRemove(index, done, ) {
            var r = confirm(this.$t('helper.modal.remove_image'));
            if (r == true) {
                done();
            }
        },
        editImage(formData, index, fileList) {},
        dataChange() {},
        openSendImage() {},
        selectPage(to) {
            if (to === "next") {
                if (this.currentPage == this.lastPage) {} else {
                    this.currentPage++;
                    this.getAllChamado("");
                    return;
                }
            } else {
                if (this.currentPage === 1) {} else {
                    this.currentPage--;
                    this.getAllChamado("");
                    return;
                }
            }
        },
        scroll() {
            setTimeout(() => {
                var objScrDiv = document.getElementById("spaceMsgs");
                objScrDiv.scrollTop = objScrDiv.scrollHeight;
            }, 1000);
        },
        view(allRow) {
            this.rowSelected = allRow;
            serviceTicket
                .read(allRow.id)
                .then((resp) => {
                    this.getUnicChamado = resp.data.reverse();
                    this.loading = false;
                    this.scroll();
                })
                .catch((err) => {
                    this.loading = false;
                });
        },
        createMsgImg() {
            this.loading = true;
            let data = {
                id: this.rowSelected.id + "/message",
                media: this.imageSelected.path,
            };
            serviceTicket
                .postID(data)
                .then((resp) => {
                    this.view(this.rowSelected);
                    this.descricaoChamado = "";
                    this.imageSelected = {};
                    this.images = [];
                    this.$root.$emit("bv::hide::modal", "modal-new-helper", "#btnShow");
                    this.loading = false;
                })
                .catch((err) => {
                    this.loading = false;
                });
        },
        createMsg() {
            this.loading = true;
            let data = {
                id: this.rowSelected.id + "/message",
                message: this.descricaoChamado,
            };
            serviceTicket
                .postID(data)
                .then((resp) => {
                    this.view(this.rowSelected);
                    this.descricaoChamado = "";
                    this.imageSelected = {};
                    this.images = [];
                    this.$root.$emit("bv::hide::modal", "modal-new-helper", "#btnShow");
                    this.loading = false;
                })
                .catch((err) => {
                    this.loading = false;
                    var error = JSON.parse(err.response.data);
                    var msg = '';
                    for (var indice in error) {
                        msg += error[indice][0] + "<br>";
                    }
                    if (msg !== '') {
                        notify('erro', msg);
                    }
                });
        },
        concluir(idRow) {
            let data = {
                id: idRow,
                status: "concluded",
            };
            this.loading = true;
            serviceTicket
                .postID(data)
                .then((resp) => {
                    this.view(idRow)
                    this.getAllChamado("");
                    this.loading = false;
                    notify('sucesso', 'Chamado concluido!');
                })
                .catch((err) => {
                    this.loading = false;
                });
        },
        getAllChamado(searchFunc, append = false) {
            this.loading = true;
            this.loadingList = true;
            let dataUrl = "";
            if (this.searchTicket != '') {
                dataUrl = "search_string=" + this.searchTicket + "&";
            }
            if (this.selectedListValue === "") {
                dataUrl += "page=" + this.currentPage;
            } else {
                dataUrl += "status[]=" + this.selectedListValue + "&page=" + this.currentPage;
            }
            serviceTicket
                .search(dataUrl)
                .then((resp) => {
                    if (append) {
                        this.AllChamados = this.AllChamados.concat(resp.data);
                    } else {
                        this.AllChamados = resp.data;
                    }
                    this.currentPage = resp.current_page;
                    this.lastPage = resp.last_page;
                    this.loading = false;
                    this.loadingList = false;
                })
                .catch((err) => {
                    this.loading = false;
                    this.loadingList = false;
                });
        },
        openModal() {
            this.$root.$emit("bv::show::modal", "modal-new-helper-V2", "#btnShow");
        },
        getPermission() {
            let permission = Cookies.get("access_level");
            let typeView = Cookies.get("type_student");
            if (permission === "admin" || permission === "owner") {
              this.permission_filters = true;
                if (typeView === 1 || typeView === "1") {
                    this.permission = false;
                    return;
                }
                this.permission = true;
            } else {
                this.permission = false;
            }
        },
        getLinkAjudaExterna() {
            serviceMeta
                .search("keys[]=external_help_link")
                .then((resp) => {
                    var data = resp.external_help_link;
                    var parans = "";
                    if (this.siteId === 5) {
                        parans = `?fn=${this.firstName}&em=${this.email}&token=w46wQyKD38SW&ph=${this.phone}`;
                    }
                    this.linkIframe = data + parans;
                })
                .catch((err) => {});
        },
        getCurrentSiteAndMember() {
          this.firstName = this.currentSite.currentMember.name;
          this.email = this.currentSite.currentMember.email;
          this.phone = this.currentSite.currentMember.email;
          this.siteId = this.currentSite.currentSite.id;
          this.getLinkAjudaExterna();
        },
        getAjudaExterna() {
            serviceMeta
                .search("keys[]=external_help")
                .then((resp) => {
                    var data = resp.external_help;
                    if (data === "on") {
                        this.customHelper = true;
                        this.getCurrentSiteAndMember();
                    }
                })
                .catch((err) => {});
        },
        handleResize() {
            this.client.width = window.innerWidth;
        },
        toggleBackground() {
            this.isClicked = !this.isClicked;
        },
        loadMore() {
            if (this.currentPage < this.lastPage) {
                this.loadinglist = true;

                this.currentPage++;
                setTimeout(() => {
                    this.getAllChamado('', true);
                }, 700);
                this.loadingList = false;
            }
        },
    },
    mounted() {
        this.getAllChamado("");
        this.getPermission();
        this.getAjudaExterna();
        this.$root.$on("bv::modal::hidden", (bvEvent, modalId) => {
            this.getAllChamado("");
            if (modalId === "modal-new-mensages") {
                this.getAllChamado("");
                this.pageTicket = 1;
            }
        });
    },
};
</script>

<style lang="scss">
.container {
    margin-top: 70px !important;
    
    @media (max-width: 768px) {
        margin-top: 0px !important;
    }
}
.divReply3 {
    margin-left: 4px;
    margin-bottom: 0;
    color: var(--fontcolor2-v2);
    background: var(--background2-v2);
    border-radius: 5px;
    padding-left: 8px;
    padding-right: 8px;
}

.divReply2 {
    color: var(--fontcolor2-v2);
    margin-bottom: 0;
}

.divReply1 {
    color: var(--fontcolor-v2);
    font-size: 14px;
    margin-right: 4px;
    margin-bottom: 0;
    font-weight: 600;
}

.divReply {
    display: flex;
    align-items: center;
    margin-right: 4px;
    margin-bottom: 0;
    margin-left: 8px;
}

.divPicSpaceInputs {
    border-radius: 150px;
    width: 32px;
    height: 32px;

    img {
        width: 100%;
        border-radius: 100%;
        z-index: 0;

    }
}

.divSpaceInputs {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 4px;
}

.divDatailTicket {
    display: flex;

    p {
        display: flex;
        align-items: center;
        width: max-content;
        color: var(--fontcolor-v2);
        margin-right: 8px;
    }

    @media (max-width: 768px) {
        margin-left: 4px;
        margin-right: 4px;
    }
}

.iconDatailTicket {
    width: 16px;
    height: 16px;
    margin-right: 8px;
}

.dateDetailTicket {
    display: flex;
    align-items: center;
    color: var(--fontcolor-v2);

    @media (max-width: 768px) {
        margin-left: 0px;
    }
}

.closeTicket {
    width: 100%;
    height: 40px;
    border: 1px solid var(--fontcolor-v2);
    color: var(--fontcolor-v2);
    border-radius: 50px;
    background-color: transparent;
}

.titleTicket {
    display: flex;
    justify-content: space-between;
    align-items: baseline;

    p {
        font-weight: 600;
        font-size: 24px;
        line-height: 29px;
        font-family: 'Montserrat Alternates';
        color: var(--fontcolor-v2);
        margin: 0px;
        height: 40px;
    }

    button {
        width: 168px;
        height: 40px;
        border: 1px solid var(--fontcolor-v2);
        color: var(--fontcolor-v2);
        border-radius: 50px;
        background-color: transparent;
    }

    @media (max-width: 768px) {
        width: 100% !important;
        border-bottom: 1px solid var(--background2-v2);
        padding-top: 20px;
        padding-bottom: 12px;
        margin-bottom: 16px;
        padding-left: 15px !important;
        padding-right: 15px !important;
        font-size: 18px;

        &::after {
            content: '';
            width: 35px;
        }

        p {
            text-align: center;
            font-size: 18px;

        }
    }
}

.divTicket {
    width: 70%;

    @media (max-width: 768px) {
        padding-left: 0px;
        padding-right: 0px;
    }
}

.detailsLastMsg {
    font-size: 14px;
    padding-right: 8px;
    overflow: hidden;
    text-overflow: ellipsis;
    max-height: 80px;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    word-break: break-all;
}

.noMsg {
    color: darkred;
}

.details {
    margin-bottom: 6px;
}

.datailsName {
    color: var(--fontcolor-v2);
    font-size: 16px;
    font-weight: 600;
}

.detailsDate {
    display: flex;
    align-items: flex-end;
    margin-left: 12px;
    text-align: center;
}

.divDetails {
    text-align: left;
    color: var(--fontcolor2-v2);
}

.divPicProfile {
    display: grid;
    place-items: center;
    border-radius: 150px;
    width: 32px;
    height: 32px;
    margin-right: 12px;
    flex-shrink: 0;

}

.layoutTicket {
    display: flex;
    border: 1.3px solid var(--background2-v2);
    border-radius: 5px;
    font-size: 13px !important;
    margin-top: 24px;
    background-color: transparent !important;

    @media (max-width: 768px) {
        margin-right: 4px;
    }
}

.dropdown-content {
    min-width: 160px;
    background: var(--background2-v2);
    border-radius: 20px;
    padding: 8px;
    padding-left: 2px;
    padding-right: 12px;
    position: relative;
    z-index: 9;

    @media (max-width: 768px) {
        z-index: 9;

    }
}

.dropdown-content a {
    display: flex;
    align-items: center;
    padding-left: 14px;
    padding-top: 12px;
    padding-bottom: 12px;
    font-size: 12px;
    text-decoration: none;
    color: var(--fontcolor);
    white-space: nowrap;
}

.dropdown-content a:hover {
    color: var(--fontcolor-v2);
}

.dropdown:hover .dropdown-content {}

.dropdown {
    @media (max-width: 768px) {
        z-index: 9;
    }

}

.summaryList {
    overflow: auto;
    display: flex;
    flex-direction: column;
    width: 30%;
    overflow-y: auto;
    height: 785px;
    padding: 0px;

    @media (max-width: 768px) {
        max-height: 500px;
        padding: 0px;

    }
}

.summaryList::-webkit-scrollbar {
    width: 2px;
}

.summaryList::-webkit-scrollbar-track {}

.summaryList::-webkit-scrollbar-thumb {
    background-color: var(--fontcolor);
    border-radius: 31px;
}

.summaryList::-webkit-scrollbar-button {
    display: none;
}

.summaryBox {
    width: 100% !important;
    display: flex !important;
    align-items: flex-start !important;
    border: 1.3px solid var(--background2-v2) !important;
    padding: 16px !important;
    border-top: 0px !important;
    border-left: 0px !important;

    @media (max-width: 768px) {}
}

.summaryBox:hover {
    background: var(--background2-v2) !important;
    transition-delay: 0, 3s;
}

.textAreaReply {
    width: calc(100% - 40px) !important;
    background: var(--background2-v2) !important;
    border-radius: 20px;
    border: none !important;
    padding: 5px 25 !important;
    padding-right: 20px !important;
    padding-top: 10px !important;
    font-size: 13px !important;
    color: var(--fontcolor-v2) !important;
    margin-left: 40px;
    height: 170px !important;
    overflow: hidden !important;
    resize: none !important;

    @media (max-width: 768px) {
        width: 100% !important;
        margin-right: 0px;
        margin-left: 0px;
        margin-top: 16px;
    }
}

.textAreaReply:focus {
    outline: none !important;
    border-color: var(--maincolor);
    box-shadow: 0 0 5px var(--maincolor);
    border: none !important;
}

.border-red {
    outline: none !important;
    border-color: #ff0e0e !important;
    box-shadow: 0 0 5px #ff0e0e !important;
    border: none !important;
}

.countLength {
    position: absolute;
    bottom: 10px;
    right: 10px;
    color: var(--maincolor);
    margin-bottom: 0;
}

.btnNewArchive {
    border: none;
    background-color: transparent;
    color: var(--maincolor);
    margin-bottom: 8px;
    margin-bottom: 0;

    @media (max-width: 768px) {
        height: 40px;
        margin-top: 0px;
    }
}

.activeRow {
    height: auto !important;
    border-left: solid var(--maincolor) 4px;
    border-radius: 4px;
    padding: 4px;
}

.spaceHelper {
    padding-top: 32px;
    margin-left: -15px;
    
    iframe {    
    min-height: 100vh;
    min-width: 100vw;    
    background-color: white;
  }
}

.inputSearchV2 {
    height: 50px;
    background: var(--background2-v2) !important;
    border-radius: 50px;
    color: var(--fontcolor) !important;
    border: none;
    padding-left: 16px;
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    line-height: 18px;

    @media (max-width: 768px) {
        height: 40px;
    }
}

.inputSearchV2 img {
    padding-right: 16px;
}

.inputSearchV2::placeholder {
    color: var(--fontcolor) !important;
    border: none;
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
}

.inputContainer {
    position: relative;
    font-size: 15px;
}

.search {
    position: absolute;
    right: 12px;
    top: 50%;
    transform: translateY(-50%);
    cursor: pointer;
    border: none;
}

.headerHelper {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.selectStatusButton {
    display: flex;
    align-items: center;
    height: 50px;
    border-radius: 50px;
    border: none;
    font-size: 15px !important;
    padding-left: 16px;
    line-height: 18.29px;
    color: var(--fontcolor) !important;
    background: var(--background2-v2) right 1.4rem center/8px 10px no-repeat !important;
    
    @media (max-width: 768px) {
        font-size: 15px !important;
        height: 40px;
        z-index: 9;
    }
}

.selectStatusButton font-awesome-icon {
    width: 16px;
    height: 16px;

}

.selectStatus {
    height: 50px;
    border-radius: 50px;
    border: none;
    line-height: 18.29px;
    color: var(--fontcolor) !important;
    background: var(--background2-v2) url("data:image/svg+xml,%3Csvg width='11' height='7' viewBox='0 0 11 7' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M0.5 1L5.5 6L10.5 1' stroke='%239A9A9A' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E") right 1.4rem center/8px 10px no-repeat !important;

    @media (max-width: 768px) {
        height: 40px;
    }
}

.selectStatus option {
    padding: 12px;
}

.spaceHelperMobile {
    width: 100vh;
    min-height: 100vh;

    iframe {
        position: absolute;
        left: 0;
        height: 100%;
        // top: 51px;
        min-height: 100vh;
    }
}

.spaceTitle {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-right: 0;

    p {
        width: 100%;
        height: 30px;
        font-family: "Montserrat Alternates";
        font-style: normal;
        font-weight: 600;
        font-size: 24px;
        line-height: 29px;
        color: var(--fontcolor);
        margin: 0px;
        padding-right: 15px;
    }

    @media (max-width: 768px) {
        border-bottom: 1px solid var(--background2-v2);
        padding-top: 20px;
        padding-bottom: 20px;
        margin-bottom: 16px;

        &::after {
            content: '';
            width: 35px;
        }

        p {
            text-align: center;
            font-size: 18px;

        }
    }
}

.arrowBack {
    border-radius: 100%;
    background: var(--background2-v2) !important;
    width: 35px;
    height: 35px;
    padding: 8px;
    color: var(--fontcolor);
}

.btnNewChamado {
    box-sizing: border-box;
    width: 173px;
    height: 50px;
    background: var(--maincolor);
    border-radius: 50px;
    border: none;
    font-size: 15px;
    color: var(--background2-v2);

    @media (max-width: 768px) {
        height: 40px;
        margin-top: 0px;
    }
}

.btnBack {
    box-sizing: border-box;
    width: 123px;
    height: 50px;
    background: var(--background2-v2);
    border-radius: 50px;
    border: none;
    margin-right: 16px;
    color: var(--fontcolor);

}

.rightSection {}

.spaceInputs {
    margin-bottom: 0px;

    .toCenterUpload {
        display: flex;
        justify-content: center;
    }

}

.divNoPicProfile {
    display: grid;
    place-items: center;
    border-radius: 100%;

    width: 32px;
    height: 32px;
    flex-shrink: 0;
}

.divNoPicProfile p {
    margin: 0;
    font-size: 16px;
}

.picProfile {
    width: 32px;
    height: 32px;
    margin: 0;
    border-radius: 50px;
    order: 1;

    img {
        width: 100%;
        border-radius: 100%;
        z-index: 0;

    }
}

.spaceModal {

    .spaceMsgs::-webkit-scrollbar {
        width: 2px;
    }

    .spaceMsgs::-webkit-scrollbar-track {}

    .spaceMsgs::-webkit-scrollbar-thumb {
        background-color: var(--fontcolor);
        border-radius: 31px;
    }

    .spaceMsgs::-webkit-scrollbar-button {
        display: none;
    }

    .spaceMsgs {
        width: 100%;
        height: 35vh;
        overflow: auto;
        margin-top: 32px;
        margin-bottom: 24px;

        .fotoPerfil {
            width: 32px;
            height: 32px;
            position: relative;

            img {
                border-radius: 100%;
            }
        }

        .avatar {
            position: relative;

            img {
                width: 32px;
                height: 32px;
                max-width: 32px !important;
            }
        }

        .headerMsg2 {
            display: grid;
            grid-template-columns: 1fr 2fr;
            gap: 14px;
            margin-bottom: 5px;
        }

        .mobileHeaderSpace {
            margin-top: 20px;
        }

        .isMinePic {
            margin-left: auto;
        }

        .textInfoMember {
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin-bottom: 8px;

            .text1 {
                font-family: Montserrat;
                font-style: normal;
                font-weight: 600;
                font-size: 12px;
                letter-spacing: 0.2px;
                color: var(--fontcolor);
                order: 2;
                text-align: start;
                width: 100%;
                margin-left: 16px;
            }

            .text1 p {
                margin: 0;
                color: var(--fontcolor2-v2);
                font-weight: normal;
            }

            .text2 {
                font-family: Montserrat;
                font-style: normal;
                font-weight: normal;
                font-size: 12px;
                letter-spacing: 0.2px;
                color: var(--fontcolor2-v2);
                order: 3;
                text-align: right;
                width: 100%;
            }
        }

        .imgDivMsgMy {
            width: 200px;
            border-radius: 3px;
            margin-bottom: 16px;
            margin-left: 46px;
        }

        .imgDivMsg {
            width: 200px;
            border-radius: 3px;
            margin-bottom: 5px;
            margin-left: 46px;
        }

        .chatMobile {
            left: 0 !important;
            right: 0 !important;
        }

        .txtMsg {
            font-family: Montserrat;
            font-style: normal;
            font-weight: 500;
            font-size: 15px;
            margin-bottom: 12px;
            margin-left: 48px;
            color: var(--fontcolor2-v2);
            word-break: break-all;
        }
    }

    .spaceFooter {
        display: flex;
        width: 100%;
        justify-content: flex-end;
    }
}

.lightbox {
    z-index: 9999;
}

.lightbox-image {
    z-index: 10;
    max-height: 95vH !important;
    width: auto !important;
    max-width: 100% !important;

    img {
        z-index: 10;
        max-height: 95vH !important;
        width: auto !important;
        max-width: 100% !important;

    }
}

.colSearch {

    padding-right: 6px;

    @media (max-width: 768px) {
        padding-right: 6px;
    }
}

.colStatus {

    padding-left: 6px;
    padding-right: 6px;

    @media (max-width: 768px) {
        padding-left: 6px;
        padding-right: 15px;
    }
}

.colButton {
    font-size: 15px;

    padding-left: 6px;

    @media (max-width: 768px) {
        padding-top: 12px;
        padding-left: 15px;
    }
}

.loading {
    text-align: center;
    position: absolute;
    color: var(--fontcolor2-v2);
    z-index: 9;
    background: var(--maincolor);
    padding: 8px 18px;
    border-radius: 8px;
    left: 9%;
    top: calc(50% - 18px);

    @media (max-width: 768px) {
        left: 30%;
    }
}
</style>
